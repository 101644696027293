import React from 'react'
import {Row, Col, Card, DatePicker} from 'antd'
import connectState from 'lyjs/lib/components/connectState'
import Trigger from 'lyjs/lib/components/Trigger'
import Render from 'lyjs/lib/components/Render'

import prettyTime from 'services/utils/prettyTime'
import Metric, {MetricMeta} from 'components/Metric'
import CoverageChart from '../../components/CoverageChart'
import WorkloadChart from '../../components/WorkloadChart'

function getRange(props) {
  return props.range
}

const TabsState = connectState({
  effects(setState) {
    return {
      updateTab(tab) {
        setState({tab})
      }
    }
  }
})(Render)

const {
  RangePicker
} = DatePicker

function Dashbord(props) {
  return (
    <Trigger
      {...props}
      actionName="fetch"
      getParams={getRange}
    >
      {(props) => {
        const {
          storeName,
          storeUrl,
          range,
          updateRange,
          pending,
          questionsCount,
          customersCount,
          assistantsCount,
          servingTime,
          dates,
          dataSource
        } = props

        const Tab = {
          COVERAGE: 'coverage',
          WORKLOAD: 'workload'
        }

        const tabContents = {
          [Tab.COVERAGE]: (
              <CoverageChart
                key={Tab.COVERAGE}
                loading={pending}
                xAxisData={dates}
                dataSource={[
                  {
                    name: '问题数',
                    data: dataSource.questions
                  },
                  {
                    name: '问题覆盖率',
                    percent: true,
                    data: dataSource.questionCoverages
                  },
                  {
                    name: '对话数',
                    data: dataSource.dialogues
                  },
                  {
                    name: '对话覆盖率',
                    percent: true,
                    data: dataSource.dialogueCoverages
                  },
                  {
                    name: '服务顾客数',
                    data: dataSource.customers
                  }
                ]}
              />
          ),
          [Tab.WORKLOAD]: (
            <WorkloadChart
              key={Tab.WORKLOAD}
              loading={pending}
              xAxisData={dates}
              dataSource={[
                {
                  name: '机器人数',
                  mix: true,
                  data: dataSource.assistants
                },
                {
                  name: '平均服务时长',
                  mix: true,
                  data: dataSource.servingTimesPerAssistant
                },
                {
                  name: '平均处理问题数',
                  data: dataSource.questionsPerAssistant
                }
              ]}
            />
          )
        }

        return (
          <div>
            <Row
              type="flex"
              gutter={16}
            >
              <Col
                span={24}
                md={16}
                xl={8}
              >
                <Metric loading={pending} >
                  <MetricMeta
                    title="店铺名称"
                    description={(
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={storeUrl}
                      >
                        {storeName}
                      </a>
                    )}
                  />
                  <RangePicker
                    size="small"
                    allowClear={false}
                    value={range}
                    onChange={updateRange}
                  />
                </Metric>
              </Col>
              <Col
                span={24}
                md={8}
                xl={4}
              >
                <Metric loading={pending} >
                  <MetricMeta
                    title="问题数"
                    description={questionsCount.toLocaleString()}
                  />
                </Metric>
              </Col>
              <Col
                span={24}
                md={8}
                xl={4}
              >
                <Metric loading={pending} >
                  <MetricMeta
                    title="服务顾客数"
                    description={customersCount.toLocaleString()}
                  />
                </Metric>
              </Col>
              <Col
                span={24}
                md={8}
                xl={4}
              >
                <Metric loading={pending} >
                  <MetricMeta
                    title="启用机器人数"
                    description={assistantsCount.toLocaleString()}
                  />
                </Metric>
              </Col>
              <Col
                span={24}
                md={8}
                xl={4}
              >
                <Metric loading={pending} >
                  <MetricMeta
                    title="服务时长"
                    description={prettyTime(servingTime, {minLevel: 2, maxLevel: 4}).toLocaleString()}
                  />
                </Metric>
              </Col>
            </Row>
            <Row>
              <TabsState initialTab="coverage" >
                {({tab, updateTab}) => (
                  <Card
                    tabList={[
                      {key: Tab.COVERAGE, tab: '覆盖率'},
                      {key: Tab.WORKLOAD, tab: '工作量'}
                    ]}
                    onTabChange={updateTab}
                  >
                    {tabContents[tab || Tab.COVERAGE]}
                  </Card>
                )}
              </TabsState>
            </Row>
          </div>
        )
      }}
    </Trigger>
  )
}

export default Dashbord
