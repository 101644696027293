import fixNumber from 'services/utils/fixNumber'
import tooltipStrRender from 'services/utils/tooltipStrRender'

export function getValue(v) {
  if (Array.isArray(v)) {
    const [
      x,
      value = x
    ] = v

    return value
  }

  if (typeof v === 'object') {
    return v.value
  }

  return v
}

function defaultCreateItem(param) {
  const {
    marker,
    seriesName,
    value
  } = param

  const v = getValue(value)

  return [
    `${marker} ${seriesName}: `,
    typeof v === 'number' ? fixNumber(v).toLocaleString() : v
  ]
}

function createTooltipFormatter(createItem, maxWidth) {
  return (params) => {
    if (Array.isArray(params)) {
      const [
        {
          axisValueLabel
        } = {}
      ] = params

      return tooltipStrRender(axisValueLabel, params.map((param) => createItem(param)))
    }

    const {
      axisValueLabel
    } = params

    return tooltipStrRender(axisValueLabel, [createItem(params)])
  }
}

export const tooltipFormatter = createTooltipFormatter(defaultCreateItem)
export default createTooltipFormatter
