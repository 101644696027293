import React from 'react'
import PropTypes from 'prop-types'
import AutoSizer from 'lyjs/lib/components/AutoSizer'
import Echart from 'lyjs/lib/components/Echart'
import cs from 'lyjs/lib/utils/classes'

function AutoSizeChart(props) {
  const {
    innerRef,
    width,
    height,
    initialWidth = width,
    initialHeight = height,
    className: classNameProp,
    style: styleProp,
    ...otherProps
  } = props

  const className = cs(classNameProp)
  const style = {
    ...styleProp,
    width,
    height
  }

  return (
    <AutoSizer
      className={className}
      style={style}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
    >
      {({width, height}) => (
        <Echart
          {...otherProps}
          ref={innerRef}
          width={width}
          height={height}
        />
      )}
    </AutoSizer>
  )
}

if (process.env.NODE_ENV !== 'production') {
  AutoSizeChart.propTypes = {
    innerRef: PropTypes.func,
    initialWidth: PropTypes.number,
    initialHeight: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.any,
    style: PropTypes.object
  }
}

export default AutoSizeChart
