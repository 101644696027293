import React from 'react'
import PropTypes from 'prop-types'
import wrapDisplayName from 'lyjs/lib/utils/wrapDisplayName'
import cs from 'lyjs/lib/utils/classes'

function withStyle(options = {}) {
  return (component) => {
    const {
      displayName = wrapDisplayName(component, 'WithStyle'),
      className: classNameOption,
      ...ownProps
    } = options

    function WithStyle(props) {
      const {
        component: Component,
        className: classNameProp,
        ...otherProps
      } = props

      const className = cs(
        classNameProp,
        classNameOption
      )

      return (
        <Component
          {...otherProps}
          className={className}
        />
      )
    }
    WithStyle.defaultProps = {
      ...ownProps,
      component
    }

    if (process.env.NODE_ENV !== 'production') {
      WithStyle.displayName = displayName
      WithStyle.propTypes = {
        component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        className: PropTypes.any
      }
    }

    return WithStyle
  }
}

export const WithStyle = withStyle({
  displayName: 'WithStyle'
})('div')
export default withStyle
