import {createSelector} from 'reselect'
import moment from 'moment'
import toObject from 'lyjs/lib/fp/toObject'
import toArray from 'lyjs/lib/fp/toArray'
import reduce from 'lyjs/lib/fp/reduce'
import map from 'lyjs/lib/fp/map'
import {Modal, notification} from 'antd'
import connectState from 'lyjs/lib/components/connectState'

import fixNumber from 'services/utils/fixNumber'
import prettyTime from 'services/utils/prettyTime'
import createStateSelector from 'services/utils/createStateSelector'
import statsApi from 'services/api/stats'

const defaultEnd = moment().startOf('d').subtract(1, 'd')
const defaultStart = moment(defaultEnd).subtract(6, 'd')

function fixNumver2(n) {
  return fixNumber(n, 2)
}

function toStatEntries(records) {
  return toObject(map((record) => {
    const {
      assistant_id,
      stats
    } = record

    return [
      assistant_id,
      toObject(map((stat) => {
        const {
          timestamp,
          mode_stats: {
            TOTAL: record
          }
        } = stat

        return [timestamp, record]
      }), stats)]
  }), records)
}

const initialState = {
  range: [defaultStart, defaultEnd],
  pending: false,
  entries: {}
}

function getExtra(record) {
  const {
    questions,
    dialogues,
    defaults,
    presales,
    postsales,
    uncovered_dialogues
  } = record

  const covered_questions_percent = questions
    ? 100 - (defaults + presales + postsales) * 100 / questions
    : 0
  const covered_dialogue_percent = dialogues
    ? 100 - uncovered_dialogues * 100 / dialogues
    : 0

  return {
    covered_questions_percent,
    covered_dialogue_percent
  }
}

const rangeSelector = createStateSelector('range')
const pendingSelector = createStateSelector('pending')
const entriesSelector = createStateSelector('entries')

const timeRangesSelector = createSelector(
  rangeSelector,
  (range) => {
    const [
      start,
      end = start
    ] = range

    let result = []
    const mCurrent = moment(start)
    const mEnd = moment(end)

    while(!mEnd.isBefore(mCurrent)) {
      result.push(moment(mCurrent))
      mCurrent.add(1, 'd')
    }

    return result
  }
)
// const entriesByAssistantSelector = createSelector(
//   timeRangesSelector,
//   entriesSelector,
//   (timeRanges, entries) => {
//     return toObject(map(([assistant, data]) => {
//       return [
//         assistant,
//         toObject(map((m) => {
//           const unix = m.unix()
//           const date = m.format('YYYY-MM-DD')
//           const {
//             [unix]: record = {}
//           } = data
//           return [
//             date,
//             record
//           ]
//         }), timeRanges)
//       ]
//     }), entries)
//   }
// )
const entriesByDateSelector = createSelector(
  timeRangesSelector,
  entriesSelector,
  (timeRanges, entries) => {
    const {
      unixs,
      dates
    } = reduce((acc, m) => {
      const unix = m.unix()
      const date = m.format('YYYY-MM-DD')

      acc.unixs.push(unix)
      acc.dates.push(date)

      return acc
    }, {
      unixs: [],
      dates: []
    }, timeRanges)

    return reduce((acc, [assistant, data]) => {
      unixs.forEach((unix, index) => {
        const {
          [index]: date
        } = dates

        if (!acc[date]) {
          acc[date] = {}
        }

        const {
          [unix]: record = {}
        } = data

        acc[date][assistant] = record
      })

      return acc
    }, {}, entries)
  }
)
const statsDataSelector = createSelector(
  entriesByDateSelector,
  (entries) => {
    return toArray(map(([date, data]) => {
      const record = reduce((result, [assistant, data]) => {
        if (data.questions !== undefined) {
          result.assistants.push(assistant)

          Object.entries(data).forEach(([key, value]) => {
            if (!result[key]) {
              result[key] = 0
            }

            result[key] += value
          })
        }

        return result
      }, {
        assistants: []
      }, data)

      const extra = getExtra(record)

      return {
        ...record,
        ...extra,
        date
      }
    }), entries)
  }
)
const statsSelector = createSelector(
  entriesSelector,
  statsDataSelector,
  (entries, dataSource) => {
    const record = reduce((result, x) => {
      const {
        date,
        assistants,
        ...data
      } = x

      Object.entries(data).forEach(([key, value]) => {
        if (!result[key]) {
          result[key] = 0
        }

        result[key] += value
      })

      return result
    }, {}, dataSource)

    const extra = getExtra(record)
    const assistants = Object.keys(entries)

    return {
      ...record,
      ...extra,
      assistants
    }
  }
)
const datesSelector = createSelector(
  entriesByDateSelector,
  (entries) => Object.keys(entries)
)
const dataSourceSelector = createSelector(
  statsDataSelector,
  (dataSource) => {
    return reduce((result, x) => {
      const {
        date,
        questions = 0,
        dialogues = 0,
        customers = 0,
        covered_questions_percent = 0,
        covered_dialogue_percent = 0,
        serving_time = 0,
        assistants: {
          length: assistants
        } = []
      } = x

      result.questions.push([
        date,
        questions / 10000
      ])
      result.dialogues.push([
        date,
        dialogues / 10000
      ])
      result.customers.push([
        date,
        customers / 10000
      ])
      result.questionCoverages.push([
        date,
        covered_questions_percent
      ])
      result.dialogueCoverages.push([
        date,
        covered_dialogue_percent
      ])
      result.assistants.push([
        date,
        assistants
      ])
      result.servingTimesPerAssistant.push([
        date,
        assistants ? prettyTime(serving_time / assistants, {minLevel: 4, maxLevel: 4, formatter: fixNumver2}) : 0
      ])
      result.questionsPerAssistant.push([
        date,
        assistants ? questions / assistants : 0
      ])

      return result
    }, {
      questions: [],
      dialogues: [],
      customers: [],
      questionCoverages: [],
      dialogueCoverages: [],
      assistants: [],
      servingTimesPerAssistant: [],
      questionsPerAssistant: []
    }, dataSource)
  }
)

const selector = createSelector(
  rangeSelector,
  pendingSelector,
  statsSelector,
  datesSelector,
  dataSourceSelector,
  (range, pending, stats, dates, dataSource) => {
    const {
      assistants = [],
      questions = 0,
      customers = 0,
      serving_time = 0
    } = stats

    return {
      range,
      pending,
      assistants,
      assistantsCount: assistants.length,
      questionsCount: questions,
      customersCount: customers,
      servingTime: serving_time,
      dates,
      dataSource
    }
  }
)

function updateRange(setState) {
  return (range) => {
    setState({range})
  }
}

function fetch(setState) {
  return (range) => {
    setState({pending: true})
    statsApi
      .fetchAssistants(range, ['TOTAL'])
      .then((data) => {
        const entries = toStatEntries(data)
        setState({entries, pending: false})
      })
      .catch((err) => {
        console.error(err)
        if (err.isApiError) {
          const {
            response: {
              status,
              data
            } = {}
          } = err
          if (status === 498) {
            Modal.error({
              title: 'Token 已过期',
              content: 'Token 已过期，请重新获取'
            })
          } else {
            notification.error({
              message: '网络错误',
              description: data || err.message,
              duration: null
            })
          }
        } else {
          notification.error({
            message: '未知的错误',
            description: err.message,
            duration: null
          })
        }
      })
  }
}

const effects = {
  updateRange,
  fetch
}

const state = connectState({
  initialState,
  selector,
  effects
})

export default state
