import {createSelector} from 'reselect'
import jwtDecode from 'jwt-decode'
import connectState from 'lyjs/lib/components/connectState'

import authTokenCache from 'services/cache/authToken'
import Stats from 'services/request/Stats'
import createStateSelector from 'services/utils/createStateSelector'

const Status = {
  PENDING: 0,
  SUCCEED: 1,
  FAILED: -1
}

function auth(authToken) {
  authTokenCache.write(authToken)
  Stats.auth(authToken)
}

const initialState = {
  storeName: '',
  storeID: 0,
  status: Status.PENDING,
  authToken: authTokenCache.read()
}

const storeNameSelector = createStateSelector('storeName')
const storeIDSelector = createStateSelector('storeID')
const statusSelector = createStateSelector('status')
const authTokenSelector = createStateSelector('authToken')

const storeUrlSelector = createSelector(
  storeIDSelector,
  (storeID) => `https://shop${storeID}.taobao.com`
)
const pendingSelector = createSelector(
  statusSelector,
  (status) => status === Status.PENDING
)
const succeedSelector = createSelector(
  statusSelector,
  (status) => status === Status.SUCCEED
)
const failedSelector = createSelector(
  statusSelector,
  (status) => status === Status.FAILED
)

const selector = createSelector(
  storeNameSelector,
  storeIDSelector,
  storeUrlSelector,
  pendingSelector,
  succeedSelector,
  failedSelector,
  (storeName, storeID, storeUrl, pending, succeed, failed) => ({
    storeName,
    storeID,
    storeUrl,
    pending,
    succeed,
    failed
  })
)

function updateAuthToken(setState, getState) {
  return (authToken) => {
    const state = getState()
    const stateAuthToken = authTokenSelector(state)
    const statePending =pendingSelector(state)
    const nextAuthToken = authToken || stateAuthToken

    if (!statePending && nextAuthToken === stateAuthToken) {
      return
    }

    try {
      const {
        store_name: storeName,
        shop_id: storeID
      } = jwtDecode(nextAuthToken)

      auth(nextAuthToken)
      setState({storeName, storeID, nextAuthToken, status: Status.SUCCEED})
    } catch (err) {
      console.error(err)
      setState({status: Status.FAILED})
    }
  }
}

const effects = {
  updateAuthToken
}

const state = connectState({
  initialState,
  selector,
  effects
})

export default state
