function createStateSelector(key, defaultValue) {
  return (state) => {
    const {
      [key]: value = defaultValue
    } = state

    return value
  }
}

export default createStateSelector
