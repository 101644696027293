const tooltipStrRender = (title, items, maxWidth = '320px') => '' +
`<div style="max-width: ${maxWidth}; padding: 4px;">
  <h4
    style="
      border-bottom: 1px solid rgba(255, 255, 255, .3);
      color: inherit;
      font-weight: normal;
      padding-bottom: .2em;
      margin-bottom: .2em;
    "
  >
    ${title}
  </h4>
  <div>
    ${items.map(([label, content]) => '' +
`   <div>
      <span>${label}</span><span>${content}</span>
    </div>`).join('\n')}
  </div>
</div>`

export default tooltipStrRender
