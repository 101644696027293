import React, {Component} from 'react'
import {Helmet} from 'react-helmet'

import {
  APP_NAME,
  APP_VERSION,
  APP_SHA,
  SITE_TITLE
} from 'services/config'
import Authentication from './containers/Authentication'
import Dashbord from './containers/Dashbord'
import './styles.css'

class Main extends Component {
  render() {
    const {
      props: {
        match: {
          query: {
            auth_token: authToken
          }
        }
      }
    } = this

    return (
      <Authentication authToken={authToken}>
        {({storeName, storeID, storeUrl}) => (
          <div className="main" >
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME}, version=${APP_VERSION}, sha=${APP_SHA}`}
              />
              <title>{`${SITE_TITLE} - ${storeName}`}</title>
            </Helmet>
            <Dashbord
              storeName={storeName}
              storeID={storeID}
              storeUrl={storeUrl}
            />
          </div>
        )}
      </Authentication>
    )
  }
}

export default Main
