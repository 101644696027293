import compose from 'lyjs/lib/fp/compose'
import createApi from 'lyjs/lib/request/createApi'
import auth from 'lyjs/lib/request/auth'
import entry from 'lyjs/lib/request/entry'

import {
  STATS_ENDPOINT,
  AUTH_HEADER
} from 'services/config'

const Stats = compose(
  auth({
    authHeader: AUTH_HEADER
  }),
  entry(),
  createApi
)({
  baseURL: STATS_ENDPOINT
})

export default Stats
