import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'moment/locale/zh-cn'
import 'antd/dist/antd.css'

import App from 'scenes/App'
import registerServiceWorker from 'registerServiceWorker'

moment.locale('zh-cn')
ReactDOM.render(
  <App />,
  document.getElementById('root'),
  () => {
    registerServiceWorker()
  }
)
