import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import theme from 'lyjs/lib/components/Echart/themes/walden'
import reduce from 'lyjs/lib/fp/reduce'

import fixNumber from 'services/utils/fixNumber'
import createTooltipFormatter, {getValue} from 'services/utils/createTooltipFormatter'
import AutoSizeChart from 'components/AutoSizeChart'

function CoverageChart(props) {
  const {
    option: optionProp = {},
    xAxisName,
    yAxisName,
    xAxisData,
    dataSource,
    ...otherProps
  } = props

  const {
    legend,
    series
  } = reduce((result, x) => {
    const {
      name,
      percent
    } = x

    if (name) {
      result.legend.push(name)
    }

    result.series.push({
      ...optionProp.series,
      ...x,
      type: percent ? 'line' : 'bar',
      yAxisIndex: percent ? 1 : 0
    })

    return result
  }, {
    legend: [],
    series: []
  }, dataSource)

  const option = {
    baseOption: {
      ...optionProp,
      ...optionProp.baseOption,
      grid: {
        ...optionProp.grid,
        left: 48,
        right: 48,
        top: 56,
        bottom: 32
      },
      xAxis: {
        ...optionProp.xAxis,
        nameGap: 36,
        nameRotate: -90,
        name: xAxisName,
        data: xAxisData
      },
      yAxis: [{
        ...optionProp.yAxis,
        name: yAxisName[0] || yAxisName,
      }, {
        ...optionProp.yAxis,
        name: yAxisName[1] || yAxisName,
        min: 0,
        max: 100,
        axisLabel: {
          formatter(value) {
            return `${value}%`
          }
        }
      }],
      tooltip: {
        ...optionProp.tooltip,
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'cross',
          snap: true
        },
        formatter: createTooltipFormatter((param) => {
          const {
            marker,
            seriesName,
            value
          } = param

          const v = fixNumber(getValue(value)).toLocaleString()

          const {
            percent,
          } = dataSource.find(({name}) => name === seriesName) || {}

          return [
            `${marker} ${seriesName}: `,
            percent ? `${v}%` : `${v}万`
          ]
        })
      },
      legend: {
        ...optionProp.legend,
        left: 'center',
        top: 0,
        data: legend
      },
      series
    },
    media: [
      ...optionProp.media || [],
      {
        query: {
          maxWidth: 670
        },
        option: {
          xAxis: {
            axisLabel: {
              formatter(value) {
                return moment(value).format('MM-DD')
              }
            }
          }
        }
      },
      {
        query: {
          maxWidth: 343
        },
        option: {
          grid: {
            top: 80
          },
          xAxis: {
            axisLabel: {
              formatter(value) {
                return moment(value).format('DD')
              }
            }
          }
        }
      }
    ]
  }

  return (
    <AutoSizeChart
      {...otherProps}
      option={option}
    />
  )
}
CoverageChart.defaultProps = {
  theme,
  height: 320,
  xAxisName: '日期',
  yAxisName: ['数量(万)', '覆盖率(%)'],
  xAxisData: [],
  dataSource: []
}

if (process.env.NODE_ENV !== 'production') {
  CoverageChart.propTypes = {
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.number.isRequired,
    option: PropTypes.object,
    xAxisName: PropTypes.string,
    yAxisName: PropTypes.arrayOf(PropTypes.string).isRequired,
    xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired
  }
}

export default CoverageChart
