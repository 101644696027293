import {
  name,
  version
} from '../../package.json'

const {
  REACT_APP_SHA = Date.now(),
  REACT_APP_SITE_TITLE,
  REACT_APP_AUTH_HEADER,
  REACT_APP_STATS_ENDPOINT,
  REACT_APP_AUTH_TOKEN_CACHE_KEY
} = process.env

export const APP_NAME = name
export const APP_VERSION = version
export const APP_SHA = REACT_APP_SHA
export const SITE_TITLE = REACT_APP_SITE_TITLE
export const AUTH_HEADER = REACT_APP_AUTH_HEADER
export const STATS_ENDPOINT = REACT_APP_STATS_ENDPOINT
export const AUTH_TOKEN_CACHE_KEY = REACT_APP_AUTH_TOKEN_CACHE_KEY
