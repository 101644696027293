import React, {Component} from 'react'
import {HashRouter as Router, Switch} from 'react-router-dom'
import {ConfigProvider} from 'antd'
import locale from 'antd/es/locale/zh_CN'

import RouteWithQuery from 'components/RouteWithQuery'
import Main from 'scenes/Main'
import './styles.css'

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={locale} >
        <Router>
          <Switch>
            <RouteWithQuery
              path="/"
              component={Main}
            />
          </Switch>
        </Router>
      </ConfigProvider>
    )
  }
}

export default App
