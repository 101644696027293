import fixNumber from 'services/utils/fixNumber'

const defaultLevel = [
  [1, ['ms', '毫秒']],
  [1e3, ['s', '秒']],
  [6e4, ['min', '分钟']],
  [3.6e6, ['h', '小时']],
  [8.64e7, ['d', '天']]
]

function prettyTime (time, options = {}) {
  const {
    minLevel = 0,
    maxLevel = 5,
    formatter = (t, suffixs, d, time) => `${fixNumber(t)} ${suffixs[1]}`,
    Level = defaultLevel
  } = options

  const normalized = Number(time)

  if (Number.isNaN(normalized)) {
    return formatter(0, Level[0][1], Level[0][0], time)
  }

  const step = (maxLevel < 1 ? 1 : maxLevel > Level.length ? Level.length : maxLevel) - 1
  const start = minLevel < 0 ? 0 : minLevel > step ? step : minLevel
  const t = normalized < 0 ? -normalized : normalized
  let d
  let suffixs

  for (let i = start; i < step; i++) {
    d = Level[i][0]
    suffixs = Level[i][1]
    const nextD = Level[i + 1][0]

    if (t < nextD) {
      return formatter(t / d, suffixs, d, time)
    }
  }

  d = Level[step][0]
  suffixs = Level[step][1]
  return formatter(t / d, suffixs, d, time)
}

export default prettyTime
