import moment from 'moment'

import StatsBase from 'services/request/Stats'

function toStartOfDayUnix(m) {
  return moment(m).startOf('d').unix()
}

class Stats extends StatsBase {
  constructor() {
    super('stats')
  }

  fetchByType(type, range = [], modes = []) {
    return this.createRequest((request, entry) => {
      const [
        start,
        end
      ] = range.map(toStartOfDayUnix)

      return request
        .get(`${entry}/${type}`, {
          params: {
            start,
            end,
            service_mode: modes.length ? modes.join(',') : undefined
          }
        })
    })
  }

  fetchShops(...args) {
    return this.fetchByType('shops', ...args)
  }

  fetchAssistants(...args) {
    return this.fetchByType('assistants', ...args)
  }
}

export default new Stats()
