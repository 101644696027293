import React from 'react'
import PropTypes from 'prop-types'
import {Alert} from 'antd'
import Trigger from 'lyjs/lib/components/Trigger'
import Render from 'lyjs/lib/components/Render'

function getAuthToken(props) {
  return props.authToken
}

function Authentication(props) {
  const {
    component,
    render,
    children,
    ...otherProps
  } = props

  return (
    <Trigger
      {...otherProps}
      actionName="updateAuthToken"
      getParams={getAuthToken}
    >
      {({storeName, storeID, storeUrl, pending, succeed, failed}) => {
        if (pending) {
          return null
        }

        if (failed) {
          return (
            <Alert
              showIcon
              type="error"
              message="未授权的访问"
              description="您无权访问该页面，请确认您的访问方式是否有问题"
            />
          )
        }

        return Render({
          storeName,
          storeID,
          storeUrl,
          component,
          render,
          children
        })
      }}
    </Trigger>
  )
}

if (process.env.NODE_ENV !== 'production') {
  Authentication.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    render: PropTypes.func,
    children: PropTypes.func
  }
}

export default Authentication
