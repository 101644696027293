import {Card} from 'antd'

import withStyle from 'components/withStyle'
import './styles.css'

const {
  Meta
} = Card

const Metric = withStyle({
  displayName: 'Metric',
  className: 'metric',
  bodyStyle: {
    padding: '24px'
  }
})(Card)

const MetricMeta = withStyle({
  displayName: 'MetricMeta',
  className: 'metric__meta'
})(Meta)

Metric.Meta = MetricMeta

export {
  MetricMeta
}
export default Metric
